<template>
    <Menu />

    <div class="mt-12 px-4" v-if="state.loaded">
        <div>
            <div class="space-y-4 px-4">
                <!-- <div>
                    <div class="text-xs font-semibold text-rojo">MARCA</div>
                    <div class="text-base">{{state.product.info.title}}</div>
                </div> -->
                <div>
                    <div class="text-xs font-semibold text-rojo">PROYECTO</div>
                    <div class="text-xl md:text-2xl lg:text-3xl">{{state.product.info.title}}</div>
                </div>
            </div>

            <div class="md:grid grid-cols-2 gap-2 mt-6 px-2">
                <div class="pb-2 md:pb-0" v-for="item in state.product.info.image" :key="item.id">
                    <img :src="item.fields.file.url" />
                </div>
            </div>

            <div class="mt-8 mb-16 px-4">
                <div v-if="richtextToHTML(state.product.info.description) != '<p>#</p>'">
                    <!-- <div class="text-xs font-semibold text-rojo mb-1">DESCRIPCIÓN</div> -->
                    <div class="space-y-2 list-disc text-base text-gray-300" v-html="richtextToHTML(state.product.info.description)"></div>
                </div>
            </div>
        </div>

        <div class="flex justify-around mb-12 border-t border-gray-700 pt-12" v-if="state.itemsLoaded">
            <button class="flex items-center space-x-2 text-gray-500 text-sm font-medium hover:text-rojo" @click="goTo(state.prev)" v-if="state.prev!=null">
                <ArrowNarrowLeftIcon class="w-6 h-6 hidden md:block" />
                <div class="hidden md:block">ANTERIOR</div>
                <ArrowLeftIcon class="w-6 h-6 md:hidden" />
            </button>

            <button class="text-gray-500 text-sm font-medium hover:text-white" @click="goTo('')">
                <div class="hidden md:block">REGRESAR A EXPERIENCIAS</div>
                <ViewGridIcon class="w-8 h-8 md:hidden" />
            </button>

            <button class="flex items-center space-x-2 text-gray-500 text-sm font-medium hover:text-rojo" @click="goTo(state.next)" v-if="state.next!=null">
                <ArrowRightIcon class="w-6 h-6 md:hidden" />
                <div class="hidden md:block">SIGUIENTE</div>
                <ArrowNarrowRightIcon class="w-6 h-6 hidden md:block" />
            </button>
        </div>
    </div>

    <div class="py-32 flex justify-center" v-else>
        <Loader />
    </div>
</template>

<script>
import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon, ViewGridIcon, ArrowLeftIcon, ArrowRightIcon } from '@heroicons/vue/solid'
import { useRoute, useRouter } from 'vue-router'
import { reactive, onMounted} from 'vue'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import Menu from '@/components/core/Menu'
import Loader from '@/components/core/Loader'
import _ from 'lodash'

let contentful = require('contentful')
const client = contentful.createClient({
    space: 'eiwnd9wc1dc0',
    accessToken: 'Kj2r0cqWM0-5r1kazWBP_ax-Z7BX0AFxWvcsD_LsS8g'
});

// route_product: 'https://cdn.contentful.com/spaces/eiwnd9wc1dc0/entries?access_token=yQ3_fGdGOE8z4Lgaqilz2vtHNUlCGZ3rk-kDeVQLGf4&content_type=product&fields.slug=',
// this.route_products = 'https://cdn.contentful.com/spaces/eiwnd9wc1dc0/entries?access_token=yQ3_fGdGOE8z4Lgaqilz2vtHNUlCGZ3rk-kDeVQLGf4&content_type=product&order=fields.order&links_to_entry=';

export default {
    components: {
        Menu,
        Loader,
        ArrowNarrowLeftIcon,
        ArrowNarrowRightIcon,
        ViewGridIcon, 
        ArrowLeftIcon,
        ArrowRightIcon
    },
    setup() {
        const router = useRouter();
        const route = useRoute();

        const state = reactive({
            route: route,
            loaded: false,
            items: [],
            itemsLoaded: false,
            next: null,
            prev: null,
            entry: null,
            product: null,
            actualCategory: null
        });

        onMounted(() => {
            init();
        });

        function init() {
            state.loaded = false;
            state.items = [];
            state.product = null;
            state.entry = [];
            state.itemsLoaded = false;
            state.next = null;
            state.prev = null;
            state.actualCategory = null;

            setTimeout(() => {
                getProduct();
            },100)
            
        }

        function getProduct() {
            client.getEntries({
                    'content_type': 'product',
                    'fields.slug': state.route.params.slug
                })
                .then(response => {
                    state.product = {
                        info: response.items[0].fields,
                        images: response.includes.Asset
                    }
                    
                    // this.product.images = _.sortBy(this.product.images, 'fields.title');
                    state.loaded = true;

                    state.entry =  state.product.info.category[0].sys.id;
                    getAll();
                })
                .catch(error => {
                    return error;
                });
        }

        function getAll() {
            client.getEntries({
                        content_type: 'product',
                        order: 'fields.order',
                        links_to_entry: state.entry,
                        limit:1000
                    })
                    .then(response => {
                        
                        state.items = response.items;
                        state.index = findTheSlug();
                        console.log(state.items.length)

                        state.actualCategory = state.items[state.index+1].fields.category[0].fields.type.toLowerCase();

                        console.log(state.index)
                        console.log(state.items.length-1)

                        if (state.index < state.items.length-1) {
                            state.next = state.items[state.index+1].fields.slug;
                        }

                        if (state.index > 0) {
                            state.prev = state.items[state.index-1].fields.slug;
                        }

                        state.itemsLoaded = true;
                    })
                    .catch(error => {
                        return error;
                    });
        }

        function findTheSlug() {
            let ind = null;

            state.items.forEach( (dt, index) => {
                if (dt.fields.slug == state.route.params.slug) {
                    ind = index;
                }
            });

            return ind;
        }

        function goTo(str) {
            if (str != '') {
                router.push('/experiencias/'+str);
            } else {
                router.push('/experiencias#'+state.actualCategory);
            }

            init();
        }

        function richtextToHTML(content) {
            return documentToHtmlString(content)
        }

        return {
            goTo,
            richtextToHTML,
            state
        }
    },
}
</script>


<template>
    <div class="loader bg-gray-700 p-3 rounded-full flex space-x-3">
        <div class="w-3 h-3 bg-gray-500 rounded-full animate-bounce"></div>
        <div class="w-3 h-3 bg-gray-500 rounded-full animate-bounce"></div>
        <div class="w-3 h-3 bg-gray-500 rounded-full animate-bounce"></div>
    </div>
</template>

<style scoped>
.loader div {
  animation-duration: .8s;
}

.loader div:first-child {
  animation-delay: 0.1s;
}

.loader div:nth-child(2) {
  animation-delay: 0.3s;
}

.loader div:nth-child(3) {
  animation-delay: 0.6s;
}
</style>